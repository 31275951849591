.ant-card-hoverable:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.ant-card {
    transition: all 0.3s ease;
    background-color: #edfafd;
}

.ant-card-meta-title {
    font-size: 15px !important;
    margin-bottom: 8px !important;
    color: #1f6473 !important;
}

.ant-card-meta-description {
    color: rgba(0,0,0,0.65);
}

.ant-list-item {
    padding: 0 !important;
}

.infinite-scroll-component {
    overflow: visible !important;
}

@media (max-width: 768px) {
    .container {
        padding: 10px;
    }
    
    .ant-card-meta-title {
        font-size: 14px;
    }
}