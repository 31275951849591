.editor-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
}

.editor-toolbar {
    padding: 8px;
    border-bottom: 1px solid #d9d9d9;
    background: #fafafa;
    border-radius: 8px 8px 0 0;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.editor-textarea {
    border: none !important;
    border-radius: 0 !important;
    min-height: 200px;
    resize: vertical;
    padding: 16px !important;
}

.editor-textarea:focus {
    box-shadow: none !important;
}

.editor-preview {
    min-height: 200px;
    padding: 16px;
    background: #fff;
    overflow-y: auto;
}

.editor-preview p {
    margin-bottom: 16px;
}

.editor-preview p:last-child {
    margin-bottom: 0;
}

.editor-preview ul,
.editor-preview ol {
    margin-bottom: 16px;
    padding-left: 24px;
}

.editor-preview li {
    margin-bottom: 8px;
}

.editor-preview li:last-child {
    margin-bottom: 0;
}

.editor-preview a {
    color: #1890ff;
    text-decoration: none;
}

.editor-preview a:hover {
    text-decoration: underline;
}

.editor-statusbar {
    padding: 4px 8px;
    border-top: 1px solid #d9d9d9;
    background: #fafafa;
    border-radius: 0 0 8px 8px;
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editor-toolbar .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
}

.editor-toolbar .ant-btn-group {
    display: flex;
    gap: 1px;
}

.editor-toolbar .ant-btn:hover {
    background-color: #e6f7ff;
    border-color: #91d5ff;
}

.editor-toolbar .ant-btn:active {
    background-color: #bae7ff;
}

/* Estilos para los tabs del editor */
.editor-wrapper .ant-tabs-nav {
    margin: 0 !important;
}

.editor-wrapper .ant-tabs-content {
    min-height: 200px;
}

/* Estilos para el texto alineado */
.editor-preview div[style*="text-align"] {
    margin-bottom: 16px;
}

/* Ajustes para mantener consistencia en la vista previa */
.editor-preview div:empty::after {
    content: '\200B'; /* Espacio invisible para mantener la altura del div */
}

/* Estilo para elementos inline */
.editor-preview strong,
.editor-preview em,
.editor-preview u {
    vertical-align: baseline;
}

.font-size-select {
    height: 32px !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px !important;
    padding: 2px 8px !important;
    margin-right: 8px !important;
    cursor: pointer !important;
    background-color: #fff !important;
    min-width: 120px !important;
    font-size: 12px !important;
}

.font-size-select:hover {
    border-color: #40a9ff !important;
}

.font-size-select:focus {
    outline: none !important;
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}

.font-size-select option {
    padding: 8px !important;
}

.simple-editor span[style*="font-size"] {
    display: inline-block;
}



@media (max-width: 576px) {
    .editor-toolbar {
        gap: 4px;
    }

    .editor-toolbar .ant-btn {
        padding: 4px 6px;
    }

    .editor-toolbar img {
        width: 16px !important;
        height: 16px !important;
    }
}