.title-view {
    font-size: 15px;
    color: #656565;
}
.ant-carousel .slick-slide {
    text-align: center;
}
.ant-carousel .slick-slide img {
    max-height: 560px;
    width: auto !important;
    margin: auto !important;
}
#description h1 {
    font-size: 21px;
}
#description h2 {
    font-size: 20px;
}
#description h3 {
    font-size: 19px;
}
#description h4 {
    font-size: 18px;
}
#description h5 {
    font-size: 17px;
}
#description h6 {
    font-size: 16px;
}
#description p {
    font-size: 15px;
}



  /* ---- MEDIA QUERIES ---- */
@media screen and (max-width: 500px){
    .ant-carousel .slick-slide img {
        width: 100% !important;
    }
}