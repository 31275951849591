body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
}

a {
  cursor: pointer;
  text-decoration: none !important;
  color: #5f848c !important;
}
a:hover {
  color: #00b6dc !important;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-spin .ant-spin-dot-item {
  background-color: #00b6dc !important;
}

a span {
  white-space: nowrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-down-medium {
  box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.16);
  -webkit-box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.16);
}

.ml-1 {
  margin-left: 3px;
}
.ml-2 {
  margin-left: 5px;
}
.ml-3 {
  margin-left: 10px;
}
.ml-4 {
  margin-left: 15px;
}
.ml-5 {
  margin-left: 20px;
}

.mr-1 {
  margin-right: 3px;
}
.mr-2 {
  margin-right: 5px;
}
.mr-3 {
  margin-right: 10px;
}
.mr-4 {
  margin-right: 15px;
}
.mr-5 {
  margin-right: 20px;
}

.m-1 {
  margin: 3px;
}
.m-2 {
  margin: 5px;
}
.m-3 {
  margin: 10px;
}
.m-4 {
  margin: 15px;
}
.m-5 {
  margin: 20px;
}

.p-1 {
  padding: 3px;
}
.p-2 {
  padding: 5px;
}
.p-3 {
  padding: 10px;
}
.p-4 {
  padding: 15px;
}
.p-5 {
  padding: 20px;
}

.pl-1 {
  padding-left: 3px;
}
.pl-2 {
  padding-left: 5px;
}
.pl-3 {
  padding-left: 10px;
}
.pl-4 {
  padding-left: 15px;
}
.pl-5 {
  padding-left: 20px;
}

.pr-1 {
  padding-right: 3px;
}
.pr-2 {
  padding-right: 5px;
}
.pr-3 {
  padding-right: 10px;
}
.pr-4 {
  padding-right: 15px;
}
.pr-5 {
  padding-right: 20px;
}

.btn-primary {
  background-color: #00b6dc !important;
  border: 1px solid #00b6dc;
  border-radius: 3px;
  font-size: 12px;
}
.btn-outline-primary {
  --bs-btn-color: #00b6dc;
  --bs-btn-border-color: #00b6dc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #07cff7;
  --bs-btn-hover-border-color: #00d5ff;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00b6dc;
  --bs-btn-active-border-color: #00b6dc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7ab9c5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #69c8db;
  --bs-gradient: none;
}
.btn-primary:hover {
  background-color: white !important;
  color: #00b6dc !important;
  border: 1px solid #00b6dc !important;
}
.btn-primary:hover img {
  filter: brightness(0) saturate(100%) invert(48%) sepia(99%) 
    saturate(1642%) hue-rotate(165deg) brightness(92%) contrast(101%);
}
.btn-primary img {
  filter: brightness(0) invert(1);
}
.btn-primary:hover img {
  filter: none;
}
.btn-secondary {
  background-color: #ffffff !important;
  border: 1px solid #00b6dc;
  border-radius: 3px;
  font-size: 12px;
  color: #11849d;
}
.btn-secondary:hover {
  background-color: #00b6dc !important;
  color: white !important;
  border: 1px solid #00b6dc;
}
.btn-secondary:hover img {
  filter: brightness(0) invert(1);
}
.btn-secondary span:hover {
  color: white !important;
}
.btn-warning {
  /*border: 1px solid #575757;*/
  background-color: rgba(255, 123, 0, 0.35);
  color: #464646;
  border-radius: 3px;
  font-size: 12px;
}
.text-warning {
  color: rgba(129, 62, 0, 0.45) !important
}
.btn-warning:hover {
  background-color: rgba(255, 123, 0, 0.45) !important;
  color: #464646 !important;
}


.bold {
  font-weight: bold;
}

.color-primary {
  color: #00b6dc !important;
}
.color-secondary {
  color: #5f848c !important;
}

.bg-primary, .bg-main {
  background-color: #00b6dc !important;
}
.bg-secondary {
  background-color: #5f848c !important;
}
.d-flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.none {
  display: none !important;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

th.ant-table-cell {
  text-transform: uppercase;
  font-size: 10px;
}
td.ant-table-cell {
  font-size: 13px;
}
table td {
  font-weight: 400;
}
.ant-table-cell a, .ant-table-cell a {
  min-width: 132px;
  width: 100%;
}
.radius-3px {
  border-radius: 3px;
}
.radius-5px {
  border-radius: 5px;
}
.radius-10px {
  border-radius: 10px;
}
.radius-15px {
  border-radius: 15px;
}
.radius-20px {
  border-radius: 20px;
}
.radius-25px {
  border-radius: 25px;
}
.color-warning {
  color: #e29b15 !important;
}



:where(.css-dev-only-do-not-override-18iikkb).ant-carousel .slick-dots li.slick-active button {
  background: #00b6dc !important;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-carousel .slick-dots li button {
  background: #494949 !important;
  opacity: 0.5;
}

.css-5ir5xx-MuiLinearProgress-bar1, .css-1r8wrcl-MuiLinearProgress-bar2 {
  background-color: #019ebe !important;
}
.css-eglki6-MuiLinearProgress-root {
  background-color: rgb(204 227 232) !important;
  height: 5px !important;
}

.ant-card {
  width: 100%;
}

.ant-card-head-title {
  font-size: 14px;
  color: #636363;
  white-space: normal !important;
}

:where(.css-dev-only-do-not-override-tpassh).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  padding: 3px 10px;
  color: #00b6dc;
}

/* ---- MEDIA QUERIES ---- */
@media screen and (max-width: 500px){
  .w-100-mb {
    width: 100% !important;
  }
}
@media screen and (max-width: 900px){
  .show-only-mb {
    display: none !important;
  }
}