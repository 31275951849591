.ant-card-head-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.ant-card-head-title {
    font-size: 15px !important;
    padding: 5px;
    color: #34626c;
    min-width: 180px;
}